import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-61c7b708"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "load_addresscla"
};
const _hoisted_2 = {
  class: "load_notecla"
};
const _hoisted_3 = {
  class: "load_addresscla"
};
const _hoisted_4 = {
  class: "load_notecla"
};
const _hoisted_5 = {
  class: "dialog-footer"
};
import { ref } from 'vue';
export default {
  __name: 'dialog-selectServe',

  setup(__props, {
    expose: __expose
  }) {
    const orderInfo = ref();
    const centerDialogVisible = ref(false);

    const showModal = data => {
      orderInfo.value = data;
      console.log(orderInfo.value);
      centerDialogVisible.value = true;
    };

    __expose({
      showModal
    });

    return (_ctx, _cache) => {
      const _component_el_col = _resolveComponent("el-col");

      const _component_el_row = _resolveComponent("el-row");

      const _component_el_avatar = _resolveComponent("el-avatar");

      const _component_el_image = _resolveComponent("el-image");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_dialog = _resolveComponent("el-dialog");

      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_dialog, {
        modelValue: centerDialogVisible.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => centerDialogVisible.value = $event),
        title: "服务详情",
        "align-center": ""
      }, {
        footer: _withCtx(() => [_createElementVNode("div", _hoisted_5, [_createVNode(_component_el_button, {
          type: "primary",
          onClick: _cache[0] || (_cache[0] = $event => centerDialogVisible.value = false)
        }, {
          default: _withCtx(() => [_createTextVNode(" 确定 ")]),
          _: 1
        })])]),
        default: _withCtx(() => [orderInfo.value.yearly_content ? (_openBlock(), _createBlock(_component_el_row, {
          key: 0,
          gutter: 20,
          align: "middle",
          class: "row"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_col, {
            span: 6
          }, {
            default: _withCtx(() => [_createTextVNode("服务内容")]),
            _: 1
          }), _createVNode(_component_el_col, {
            span: 18
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(orderInfo.value.yearly_content), 1)]),
            _: 1
          })]),
          _: 1
        })) : _createCommentVNode("", true), orderInfo.value.yearly_frequency ? (_openBlock(), _createBlock(_component_el_row, {
          key: 1,
          gutter: 20,
          align: "middle",
          class: "row"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_col, {
            span: 6
          }, {
            default: _withCtx(() => [_createTextVNode("上门服务频次")]),
            _: 1
          }), _createVNode(_component_el_col, {
            span: 18
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(orderInfo.value.yearly_frequency), 1)]),
            _: 1
          })]),
          _: 1
        })) : _createCommentVNode("", true), orderInfo.value.load_address ? (_openBlock(), _createBlock(_component_el_row, {
          key: 2,
          gutter: 20,
          class: "row",
          align: "middle"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_col, {
            span: 6
          }, {
            default: _withCtx(() => [_createVNode(_component_el_avatar, {
              src: "https://www.ipolarx.com/api/rame/getImage?image=zhuang.png"
            })]),
            _: 1
          }), _createVNode(_component_el_col, {
            span: 18
          }, {
            default: _withCtx(() => [_createElementVNode("div", null, _toDisplayString(orderInfo.value.load_address), 1), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", null, _toDisplayString(orderInfo.value.load_name) + _toDisplayString(orderInfo.value.load_phone), 1), _createElementVNode("div", _hoisted_2, _toDisplayString(orderInfo.value.load_note), 1)])]),
            _: 1
          })]),
          _: 1
        })) : _createCommentVNode("", true), orderInfo.value.unload_address ? (_openBlock(), _createBlock(_component_el_row, {
          key: 3,
          gutter: 20,
          align: "middle",
          class: "row"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_col, {
            span: 6
          }, {
            default: _withCtx(() => [_createVNode(_component_el_avatar, {
              src: "https://www.ipolarx.com/api/rame/getImage?image=xie.png"
            })]),
            _: 1
          }), _createVNode(_component_el_col, {
            span: 18
          }, {
            default: _withCtx(() => [_createElementVNode("div", null, _toDisplayString(orderInfo.value.unload_address), 1), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", null, _toDisplayString(orderInfo.value.unload_name) + _toDisplayString(orderInfo.value.unload_phone), 1), _createElementVNode("div", _hoisted_4, _toDisplayString(orderInfo.value.unload_note1), 1)])]),
            _: 1
          })]),
          _: 1
        })) : _createCommentVNode("", true), orderInfo.value.tank_urls ? (_openBlock(), _createBlock(_component_el_row, {
          key: 4,
          gutter: 20,
          class: "row",
          align: "middle"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_col, {
            span: 6
          }, {
            default: _withCtx(() => [_createTextVNode("鱼缸照片")]),
            _: 1
          }), _createVNode(_component_el_col, {
            span: 18
          }, {
            default: _withCtx(() => [_createVNode(_component_el_row, {
              class: "row"
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(orderInfo.value.tank_urls.split(','), (item, index) => {
                return _openBlock(), _createBlock(_component_el_col, {
                  span: 4,
                  key: index
                }, {
                  default: _withCtx(() => [_createVNode(_component_el_image, {
                    style: {
                      "width": "100px",
                      "height": "100px"
                    },
                    src: item,
                    fit: "cover"
                  }, null, 8, ["src"])]),
                  _: 2
                }, 1024);
              }), 128))]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        })) : _createCommentVNode("", true), orderInfo.value.repairs_urls ? (_openBlock(), _createBlock(_component_el_row, {
          key: 5,
          gutter: 20,
          class: "row",
          align: "middle"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_col, {
            span: 6
          }, {
            default: _withCtx(() => [_createTextVNode("检修照片")]),
            _: 1
          }), _createVNode(_component_el_col, {
            span: 18
          }, {
            default: _withCtx(() => [_createVNode(_component_el_row, {
              class: "row"
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(orderInfo.value.repairs_urls.split(','), (item, index) => {
                return _openBlock(), _createBlock(_component_el_col, {
                  span: 4,
                  key: index
                }, {
                  default: _withCtx(() => [_createVNode(_component_el_image, {
                    style: {
                      "width": "100px",
                      "height": "100px"
                    },
                    src: item,
                    fit: "cover"
                  }, null, 8, ["src"])]),
                  _: 2
                }, 1024);
              }), 128))]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        })) : _createCommentVNode("", true), orderInfo.value.repairs ? (_openBlock(), _createBlock(_component_el_row, {
          key: 6,
          gutter: 20,
          class: "row",
          align: "middle"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_col, {
            span: 6
          }, {
            default: _withCtx(() => [_createTextVNode("检修部件")]),
            _: 1
          }), _createVNode(_component_el_col, {
            span: 18
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(orderInfo.value.repairs), 1)]),
            _: 1
          })]),
          _: 1
        })) : _createCommentVNode("", true), orderInfo.value.ill_urls ? (_openBlock(), _createBlock(_component_el_row, {
          key: 7,
          gutter: 20,
          class: "row",
          align: "middle"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_col, {
            span: 6
          }, {
            default: _withCtx(() => [_createTextVNode("鱼病照片")]),
            _: 1
          }), _createVNode(_component_el_col, {
            span: 18
          }, {
            default: _withCtx(() => [_createVNode(_component_el_row, {
              class: "row"
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(orderInfo.value.ill_urls.split(','), (item, index) => {
                return _openBlock(), _createBlock(_component_el_col, {
                  span: 4,
                  key: index
                }, {
                  default: _withCtx(() => [_createVNode(_component_el_image, {
                    style: {
                      "width": "100px",
                      "height": "100px"
                    },
                    src: item,
                    fit: "cover"
                  }, null, 8, ["src"])]),
                  _: 2
                }, 1024);
              }), 128))]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        })) : _createCommentVNode("", true), orderInfo.value.landscapingId ? (_openBlock(), _createBlock(_component_el_row, {
          key: 8,
          gutter: 20,
          align: "middle",
          class: "row"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_col, {
            span: 6
          }, {
            default: _withCtx(() => [_createTextVNode("已选景观")]),
            _: 1
          }), _createVNode(_component_el_col, {
            span: 18
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(orderInfo.value.landscapingInfo.name) + " " + _toDisplayString(orderInfo.value.landscapingInfo.code), 1)]),
            _: 1
          })]),
          _: 1
        })) : _createCommentVNode("", true), orderInfo.value.size ? (_openBlock(), _createBlock(_component_el_row, {
          key: 9,
          gutter: 20,
          align: "middle",
          class: "row"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_col, {
            span: 6
          }, {
            default: _withCtx(() => [_createTextVNode("鱼缸尺寸")]),
            _: 1
          }), _createVNode(_component_el_col, {
            span: 18
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(orderInfo.value.size), 1)]),
            _: 1
          })]),
          _: 1
        })) : _createCommentVNode("", true), orderInfo.value.other_content ? (_openBlock(), _createBlock(_component_el_row, {
          key: 10,
          gutter: 20,
          align: "middle",
          class: "row"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_col, {
            span: 6
          }, {
            default: _withCtx(() => [_createTextVNode("服务内容")]),
            _: 1
          }), _createVNode(_component_el_col, {
            span: 18
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(orderInfo.value.other_content), 1)]),
            _: 1
          })]),
          _: 1
        })) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["modelValue"])]);
    };
  }

};