import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "cell-item"
};
const _hoisted_2 = {
  class: "my-label"
};
const _hoisted_3 = {
  key: 0
};
const _hoisted_4 = {
  key: 1
};
const _hoisted_5 = {
  class: "dialog-footer"
};
import { ref } from 'vue';
export default {
  __name: 'dialog-againPayMentInfo',

  setup(__props, {
    expose: __expose
  }) {
    const orderInfo = ref();
    const DialogVisible = ref(false);

    const showModal = data => {
      console.log('接收');
      orderInfo.value = data;
      console.log(orderInfo.value);
      DialogVisible.value = true;
    };

    __expose({
      showModal
    });

    return (_ctx, _cache) => {
      const _component_el_descriptions_item = _resolveComponent("el-descriptions-item");

      const _component_el_descriptions = _resolveComponent("el-descriptions");

      const _component_el_divider = _resolveComponent("el-divider");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_dialog = _resolveComponent("el-dialog");

      return _openBlock(), _createBlock(_component_el_dialog, {
        modelValue: DialogVisible.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => DialogVisible.value = $event),
        title: "尾款明细",
        "align-center": ""
      }, {
        footer: _withCtx(() => [_createElementVNode("div", _hoisted_5, [_createVNode(_component_el_button, {
          type: "primary",
          onClick: _cache[0] || (_cache[0] = $event => DialogVisible.value = false)
        }, {
          default: _withCtx(() => [_createTextVNode(" 确定 ")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_el_descriptions, {
          class: "margin-top",
          column: 1,
          size: _ctx.size,
          border: "",
          title: "商品"
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(orderInfo.value.goodsInfo, (item, index) => {
            return _openBlock(), _createBlock(_component_el_descriptions_item, {
              key: index
            }, {
              label: _withCtx(() => [_createElementVNode("div", _hoisted_1, _toDisplayString(item.subject), 1)]),
              default: _withCtx(() => [_createElementVNode("div", null, _toDisplayString(item.sortPrice), 1)]),
              _: 2
            }, 1024);
          }), 128))]),
          _: 1
        }, 8, ["size"]), _createVNode(_component_el_divider), orderInfo.value.records[0] ? (_openBlock(), _createBlock(_component_el_descriptions, {
          key: 0,
          class: "margin-top",
          column: 1,
          size: _ctx.size,
          title: "费用",
          border: ""
        }, {
          default: _withCtx(() => [orderInfo.value.records[0].kp_cost ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
            key: 0,
            label: "空跑费"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(orderInfo.value.records[0].kp_cost), 1)]),
            _: 1
          })) : _createCommentVNode("", true), orderInfo.value.records[0].cj_cost ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
            key: 1,
            label: "超距费"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(orderInfo.value.records[0].cj_cost), 1)]),
            _: 1
          })) : _createCommentVNode("", true), orderInfo.value.records[0].ygs_cost ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
            key: 2,
            label: "原水费"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(orderInfo.value.records[0].ygs_cost), 1)]),
            _: 1
          })) : _createCommentVNode("", true), orderInfo.value.records[0].records ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
            key: 3,
            label: "超员费"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(orderInfo.value.records[0].cy_cost), 1)]),
            _: 1
          })) : _createCommentVNode("", true), orderInfo.value.records[0].ltby_cost ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
            key: 4,
            label: "楼梯搬运费"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(orderInfo.value.records[0].ltby_cost), 1)]),
            _: 1
          })) : _createCommentVNode("", true), orderInfo.value.records[0].expense ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
            key: 5,
            label: "工费"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(orderInfo.value.records[0].expense), 1)]),
            _: 1
          })) : _createCommentVNode("", true), orderInfo.value.records[0].discount ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
            key: 6,
            label: "优惠",
            "label-class-name": "my-label"
          }, {
            default: _withCtx(() => [_createElementVNode("div", _hoisted_2, _toDisplayString(orderInfo.value.records[0].discount), 1)]),
            _: 1
          })) : _createCommentVNode("", true), orderInfo.value.records[0].final_payment ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
            key: 7,
            label: "实付尾款"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(orderInfo.value.records[0].final_payment), 1)]),
            _: 1
          })) : _createCommentVNode("", true), orderInfo.value.records[0].trade_uuid_final ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
            key: 8,
            label: "交易号"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(orderInfo.value.records[0].trade_uuid_final), 1)]),
            _: 1
          })) : _createCommentVNode("", true), orderInfo.value.records[0].pay_status ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
            key: 9,
            label: "支付状态"
          }, {
            default: _withCtx(() => [orderInfo.value.records[0].pay_status == 4 ? (_openBlock(), _createElementBlock("text", _hoisted_3, "未付尾款")) : _createCommentVNode("", true), orderInfo.value.records[0].pay_status == 5 ? (_openBlock(), _createElementBlock("text", _hoisted_4, "已付尾款")) : _createCommentVNode("", true)]),
            _: 1
          })) : _createCommentVNode("", true), orderInfo.value.records[0].final_payment_At ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
            key: 10,
            label: "付款时间"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(orderInfo.value.records[0].final_payment_At), 1)]),
            _: 1
          })) : _createCommentVNode("", true), orderInfo.value.records[0].pay_way ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
            key: 11,
            label: "支付方式"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(orderInfo.value.records[0].pay_way), 1)]),
            _: 1
          })) : _createCommentVNode("", true)]),
          _: 1
        }, 8, ["size"])) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["modelValue"]);
    };
  }

};