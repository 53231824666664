import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-354fb54c"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "dialog-footer"
};
import { ref } from 'vue';
export default {
  __name: 'dialog-store',

  setup(__props, {
    expose: __expose
  }) {
    const storeInfo = ref();
    const centerDialogVisible = ref(false);

    const showModal = data => {
      console.log('接收');
      storeInfo.value = data;
      console.log(storeInfo.value);
      centerDialogVisible.value = true;
    };

    __expose({
      showModal
    });

    return (_ctx, _cache) => {
      const _component_el_col = _resolveComponent("el-col");

      const _component_el_row = _resolveComponent("el-row");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_dialog = _resolveComponent("el-dialog");

      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_dialog, {
        modelValue: centerDialogVisible.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => centerDialogVisible.value = $event),
        title: "店铺信息",
        "align-center": ""
      }, {
        footer: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_button, {
          type: "primary",
          onClick: _cache[0] || (_cache[0] = $event => centerDialogVisible.value = false)
        }, {
          default: _withCtx(() => [_createTextVNode(" 确定 ")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_el_row, {
          gutter: 20,
          class: "row"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_col, {
            span: 6
          }, {
            default: _withCtx(() => [_createTextVNode("店铺名称")]),
            _: 1
          }), _createVNode(_component_el_col, {
            span: 18
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(storeInfo.value.name), 1)]),
            _: 1
          })]),
          _: 1
        }), _createVNode(_component_el_row, {
          gutter: 20,
          class: "row"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_col, {
            span: 6
          }, {
            default: _withCtx(() => [_createTextVNode("店铺地址")]),
            _: 1
          }), _createVNode(_component_el_col, {
            span: 18
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(storeInfo.value.address), 1)]),
            _: 1
          })]),
          _: 1
        }), _createVNode(_component_el_row, {
          gutter: 20,
          class: "row"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_col, {
            span: 6
          }, {
            default: _withCtx(() => [_createTextVNode("联系方式")]),
            _: 1
          }), _createVNode(_component_el_col, {
            span: 18
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(storeInfo.value.phone), 1)]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["modelValue"])]);
    };
  }

};