import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-7d7175f0"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  style: {
    "height": "500px"
  }
};
const _hoisted_2 = {
  class: "dialog-footer"
};
import { ref } from 'vue';
import { getProgressList } from '@/api/order';
export default {
  __name: 'dialo-dailyRecodeInfo',

  setup(__props, {
    expose: __expose
  }) {
    const orderInfo = ref();
    const DialogVisible = ref(false);

    const showModal = data => {
      console.log('接收');
      newstepslist.value = []; // eslint-disable-next-line no-const-assign

      orderInfo.value = data;
      console.log(orderInfo.value);
      getProgressData(orderInfo.value.orderId);
      DialogVisible.value = true;
    }; // 控制当前步骤条的下标 从0开始


    const active = ref(0); // const stepslist = []
    // 获取

    const newstepslist = ref();
    const isevaluate = ref(false);

    const getProgressData = async orderId => {
      const stepslist = [{
        title: '在线预约',
        time: '',
        content: '创单时间：'
      }, {
        title: '已付款',
        time: '',
        content: '付款时间：'
      }, {
        title: '商家确认',
        time: '',
        content: '确认时间：'
      }, {
        title: '赶往途中...',
        time: '',
        content: '出发时间：'
      }, {
        title: '服务中...',
        time: '',
        content: '开始时间：'
      }, {
        title: '已评价',
        time: '',
        content: '评价时间：'
      }, {
        title: '已完结'
      }];
      const data = {
        orderId
      };

      try {
        const resData = await getProgressList(data);

        if (resData.status === 'success') {
          console.log(resData.data);
          resData.data.map((item, index) => {
            console.log(item.node_name);

            if (item.node_name === '付尾款') {
              console.log('付尾款');
              const stepsitem = {
                title: '付尾款',
                time: item.nodeAt,
                content: '付款时间'
              };
              stepslist.splice(4, 0, stepsitem); // console.log(stepslist)
            }

            if (item.node_name === '已评价') {
              isevaluate.value = true;
            }

            stepslist.map((item1, index1) => {
              if (item.node_name === item1.title) {
                stepslist[index1].time = item.nodeAt;
                stepslist[index1].price = item.price;
              }
            });
          });

          if (isevaluate.value) {
            active.value = 8;
          } else {
            stepslist.map((item, index) => {
              // console.log(item.time)
              if (item.time) {
                console.log(index);
                active.value = index + 1;
              }
            });
          } // console.log(stepslist)
          // newstepslist.value = stepslist
          // console.log(newstepslist.value)


          console.log(stepslist);
          newstepslist.value = stepslist;
        }
      } catch (err) {
        console.log(err);
      }
    };

    __expose({
      showModal
    });

    return (_ctx, _cache) => {
      const _component_el_step = _resolveComponent("el-step");

      const _component_el_steps = _resolveComponent("el-steps");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_dialog = _resolveComponent("el-dialog");

      return _openBlock(), _createBlock(_component_el_dialog, {
        modelValue: DialogVisible.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => DialogVisible.value = $event),
        title: "日志",
        "align-center": ""
      }, {
        footer: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_button, {
          type: "primary",
          onClick: _cache[0] || (_cache[0] = $event => DialogVisible.value = false)
        }, {
          default: _withCtx(() => [_createTextVNode(" 确定 ")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_steps, {
          style: {},
          active: active.value,
          "finish-status": "finish",
          direction: "vertical",
          "process-status": "wait"
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(newstepslist.value, (item, index) => {
            return _openBlock(), _createBlock(_component_el_step, {
              key: index
            }, {
              title: _withCtx(() => [_createTextVNode(_toDisplayString(item.title) + " " + _toDisplayString(item.price), 1)]),
              description: _withCtx(() => [_createTextVNode(_toDisplayString(item.content) + " " + _toDisplayString(item.time), 1)]),
              _: 2
            }, 1024);
          }), 128))]),
          _: 1
        }, 8, ["active"])])]),
        _: 1
      }, 8, ["modelValue"]);
    };
  }

};